<template>
  <div class="mobile-home">
    <div class="mobile-home-header">
      <img src="../../../assets/mobile_images/mo_banner2.png" alt="" />
      <!-- <div class="mobile-header-btn">进一步了解</div> -->
    </div>
    <!-- 整体方案介绍 -->
    <mobileTitle titleZh="整体方案介绍" marginTopZh=".64rem" marginBottomZh=".34rem"></mobileTitle>
    <div class="mobile-case-box">
      <p>
        传世通过对全院后勤物资的集中管理与统一规划，搭建从员外到院内的物流供应链信息平台，借助先进的管理手段和自动化设备，以形成安全、高效、规范的医院物流管理体系，全面提升医院物流管理水平。
      </p>
      <img src="../../../assets/mobile_images/mo_hospital_logistics_ztfajs.png" alt="" />
    </div>
    <!-- 信息化建设方案 -->
    <mobileTitle titleZh="信息化建设方案" marginTopZh="1.26rem" marginBottomZh=".54rem"></mobileTitle>
    <div class="mobile-grey-box">
      <mobileImg :url="xxhImg" boxHeight="7.74rem" imgHeight="7.74rem" ></mobileImg>
    </div>
    <!-- 智能化建设方案 -->
    <mobileTitle titleZh="智能化建设方案" marginTopZh=".64rem" marginBottomZh=".58rem"></mobileTitle>
    <img class="w90f" src="../../../assets/mobile_images/mo_hospital_logistics_znh.png" alt="" />
    <!-- 系统化建设方案 -->
    <mobileTitle titleZh="系统化建设方案" marginTopZh=".64rem" marginBottomZh=".58rem"></mobileTitle>
    <mobileColumnBox :boxList="boxList"></mobileColumnBox>
    <!-- 应用案例 -->
    <mobileTitle titleZh="应用案例" marginTopZh=".64rem" marginBottomZh=".58rem"></mobileTitle>
    <img class="w100f" src="../../../assets/mobile_images/mo_hospital_logistics_case.png" alt="" />
  </div>
</template>
<script>
import mobileColumnBox from '@/components/box/mobile_column_box.vue'; //盒子组件
import mobileTitle from '@/components/title/mobile_title.vue'; //标题组件
import mobileImg from '@/components/img/mobile_img.vue'; //图片组件
export default {
  components: {
    mobileTitle,
    mobileColumnBox,
    mobileImg
  },
  data() {
    return {
      xxhImg:require('../../../assets/mobile_images/mo_hospital_logistics_xxh.png'),
      boxList: [
        {
          url: require('../../../assets/mobile_images/mo_hospital_logistics_xth1.png'),
          title: '搭建院内物流管理体系',
          txt:
            '传世为医院搭建安全、高效的物流运作系统。通过设置科学的岗位结构，设计高效的作业流程，制定标准的作业规范，构建完善的管理系统体系，助力医院打造国际一流的现代化综合医院。',
        },
        {
          url: require('../../../assets/mobile_images/mo_hospital_logistics_xth2.png'),
          title: '延伸院内物流增值服务',
          txt:
            '传世搭建院内物流管理体系和提供院内物流服务的同时，提供中立的院内物流服务，为医院提供包括物流中心建设和运营、环境保洁、患者及医疗辅助运送、设备运行与维护、用品发放、职工餐与营养餐等在内延伸增值服务。',
        },
        // {
        //   url: require('../../../assets/mobile_images/mo_hospital_logistics_xth3.png'),
        //   title: '延伸院内物流增值服务',
        //   txt:
        //     '传世搭建院内物流管理体系和提供院内物流服务的同时，提供中立的院内物流服务，为医院提供包括物流中心建设和运营、环境保洁、患者及医疗辅助运送、设备运行与维护、用品发放、职工餐与营养餐等在内延伸增值服务。',
        // },
        {
          url: require('../../../assets/mobile_images/mo_hospital_logistics_xth4.png'),
          title: '助力打造一流的现代化医院',
          txt:
            '传世通过搭建院内物流管理系统、提供院内物流服务和延伸增值服务，将医院物流运作水平提升到行业领先，打造完善、科学的物流运营体系，助力医院打造一流的现代化综合医院。',
        },
      ],
    };
  },
};
</script>
<style lang="less" scoped>
.mobile-home {
  margin: 0 auto;
  .mobile-home-header {
    position: relative;
    min-height: 5rem;
    img {
      width: 100%;
    }
    .mobile-header-btn {
      position: absolute;
      left: 50%;
      bottom: 1.8rem;
      transform: translateX(-50%);
      width: 2.4rem;
      height: 0.72rem;
      line-height: 0.72rem;
      font-size: 0.28rem;
      text-align: center;
      color: #fff;
      background: #4fa1a4;
      border-radius: 0.04rem;
    }
  }
  .mobile-case-box {
    padding: 0 0.63rem;
    box-sizing: border-box;
    p {
      color: #808080;
      font-size: 0.28rem;
      line-height: 0.56rem;
      text-align: left;
    }
    img {
      width: 100%;
      margin-top: 0.58rem;
    }
  }
  .mobile-grey-box {
    width: 100%;
    height: 8.74rem;
    padding: 0.4rem;
    background: #f7f7f7;
    box-sizing: border-box;
    .img-box{
      overflow-x: auto;
      overflow-y: hidden;
      margin-bottom: .2rem;
      img {
        height: 7.74rem;
      }
    }
    
  }
  
}
</style>
