<template>
  <div class="home-block">
    <div class="home">
      <!-- 头部开始 -->
      <div class="home-header">
        <img class="w100f" src="../../assets/images/hospital_bg111.jpg" alt="" />
        <div class="bg-text">
          <div class="bg-text-left">
            <p class="font-title">医院物流建设整体解决方案</p>
            <div class="font-title-txt">
              传世科技在助力医院推动重点学科建设的同时，注重打造符合现代化医院需求的物<br />流综合管理体系。通过对全院后勤物资的集中管理与统一规划，借助先进的管理手<br />段和智能化设备，形成安全、高效、规范的医院物流管理体系。
            </div>
            <!-- <div class="next-btn mt60">进一步了解</div> -->
          </div>
          <!-- <div class="bg-text-right">
            <p class="font-one">Technology leads transform, Data drives serviceValue</p>
            <p class="font-two">Transparent Word</p>
          </div> -->
        </div>
      </div>
      <!-- 头部结束 -->
      <!-- 中间内容开始 -->
      <div class="main-contain">
        <div class="case-title">整体方案介绍</div>
        <div class="case-block-one">
          <img src="../../assets/images/hospital_logistics_a1.png" alt="" />
          <div class="block-one-box">
            <div class="block-one-title">医院物流建设整体解决方案</div>
            <div class="block-one-content">
              传世通过对全院后勤物资的集中管理与统一规划，搭建从员外到院内的物流供应链信息平台，借助先进的管理手段和自动化设备，以形成安全、高效、规范的医院物流管理体系，全面提升医院物流管理水平。
            </div>
          </div>
        </div>
        <Title titleZh="三大建设方案" titleEn=""></Title>
        <Tab :tabs="tabs" @tabChange="onTabChange"> </Tab>
        <div class="h63"></div>
        <img v-show="currentIndex == 0" class="w88f" src="../../assets/images/hospital_logistics_b1.png" alt="" />
        <img v-show="currentIndex == 1" class="w68f" src="../../assets/images/hospital_logistics_b2.png" alt="" />
        <div class="tab-three-box" v-show="currentIndex == 2">
          <div class="tab-three-list" v-for="(item, index) in boxList" :key="index">
            <img :src="item.url" alt="" />
            <div class="tab-three-title">{{ item.title }}</div>
            <div class="tab-three-txt">{{ item.txt }}</div>
          </div>
        </div>
        <Title titleZh="应用案例" titleEn=""></Title>
        <img class="w100f" src="../../assets/images/hospital_logistics_case.png" alt="" />
        <div class="h150"></div>
      </div>
    </div>
    <mobilePage></mobilePage>
  </div>
</template>

<script>
import mobilePage from '@/views/hospital/mobile/hospital_logistics.vue'; //移动端页面
import Tab from '@/components/tab/tab.vue'; //选项卡组件
import Box from '@/components/box/box.vue'; //盒子组件
import Title from '@/components/title/title.vue'; //标题组件
export default {
  name: 'home',
  components: {
    Box,
    Title,
    Tab,
    mobilePage
  },
  data() {
    return {
      currentIndex: 0,
      tabs: [{ title: '医院物流信息化建设' }, { title: '医院物流智能化建设' }, { title: '医院物流体系化建设' }],
      boxList: [
        {
          url: require('../../assets/images/hospital_logistics_c1.png'),
          title: '搭建院内物流管理体系',
          txt:
            '传世为医院搭建安全、高效的物流运作系统。通过设置科学的岗位结构，设计高效的作业流程，制定标准的作业规范，构建完善的管理系统体系，助力医院打造国际一流的现代化综合医院。',
        },
        {
          url: require('../../assets/images/hospital_logistics_c2.png'),
          title: '延伸院内物流增值服务',
          txt:
            '传世搭建院内物流管理体系和提供院内物流服务的同时，提供中立的院内物流服务，为医院提供包括物流中心建设和运营、环境保洁、患者及医疗辅助运送、设备运行与维护、用品发放、职工餐与营养餐等在内延伸增值服务。',
        },
        // {
        //   url: require('../../assets/images/hospital_logistics_c3.png'),
        //   title: '延伸院内物流增值服务',
        //   txt:
        //     '传世搭建院内物流管理体系和提供院内物流服务的同时，提供中立的院内物流服务，为医院提供包括物流中心建设和运营、环境保洁、患者及医疗辅助运送、设备运行与维护、用品发放、职工餐与营养餐等在内延伸增值服务。',
        // },
        {
          url: require('../../assets/images/hospital_logistics_c4.png'),
          title: '助力打造一流的现代化医院',
          txt:
            '传世通过搭建院内物流管理系统、提供院内物流服务和延伸增值服务，将医院物流运作水平提升到行业领先，打造完善、科学的物流运营体系，助力医院打造一流的现代化综合医院。',
        },
      ],
    };
  },
  methods: {
    onTabChange(item, index) {
      this.currentIndex = index;
      console.log(index);
    },
  },
};
</script>
<style lang="less" scoped>
.home {
  //头部
  .home-header {
    position: relative;
    width: 100%;
    min-height: 450px;
    .bg-text {
      width: 1200px;
      // height: 200px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: #fff;
      .bg-text-left {
        text-align: left;
        .font-title {
          font-size: 45px;
          color: #3D3D3D;
          margin-bottom: 30px;
        }
        .font-title-txt {
          font-size: 16px;
          font-weight: 400;
          line-height: 42px;
          color: #3D3D3D;
        }
        .next-btn {
          width: 140px;
          height: 40px;
          line-height: 40px;
          font-size: 18px;
          background: #4fa1a4;
          opacity: 1;
          border-radius: 24px;
          text-align: center;
          cursor: pointer;
        }
      }
      .bg-text-right {
        font-family: Source Han Sans CN;
        text-align: right;
        .font-one {
          font-size: 30px;
        }
        .font-two {
          font-size: 22px;
          line-height: 22px;
        }
      }
    }
  }
  //中间
  .main-contain {
    width: 1200px;
    .case-title {
      font-size: 40px;
      margin-top: 136px;
      margin-bottom: 77px;
      text-align: center;
    }
    .case-block-one {
      height: 100%;
      display: flex;
      img {
        width: 68%;
      }
      .block-one-box {
        display: flex;
        justify-content: center;
        flex-direction: column;
        flex: 1;
        text-align: left;
        padding: 0 44px;
        background: #fafafa;
        .block-one-title {
          font-size: 20px;
          color: #3d3d3d;
          margin-bottom: 32px;
        }
        .block-one-content {
          font-size: 16px;
          color: #3d3d3d;
          line-height: 28px;
        }
      }
    }
    .tab-three-box {
      width: 92%;
      display: flex;
      flex-wrap: wrap;
      margin-left: 4%;
      .tab-three-list {
        width: calc(50% - 70px);
        margin-bottom: 121px;
        img{
            width: 100%;
        }
        &:nth-child(2n + 1) {
          margin-right: 140px;
        }
        .tab-three-title{
            font-size: 24px;
            text-align: left;
            margin: 24px 0;
        }
        .tab-three-txt{
            font-size: 16px;
            text-align: left;
            line-height: 30px;
        }
      }
    }
  }
}
</style>
